import gql from 'graphql-tag';
import { mapActions } from 'vuex';
import { isNil } from '@/shared/utils';
import { addressFormatter } from '@/shared/fomaters';
import languageMessages from '@/mixins/languageMessages';
import AddressForm from '@/components/Forms/Address';
import globalMixin from '@/mixins/global.mixin';

export default {
	name: 'AddressHelperMixin',
	components: { AddressForm },
	mixins: [languageMessages, globalMixin],
	props: {
		params: {
			type: Object,
			required: false,
			default: () => {}
		},
		addressId: {
			type: Number,
			required: false,
			default: 0
		}
	},
	data() {
		return {
			isAddressModalOpen: false,
			editAddressValue: null,
			addressPayload: {},
			rendFilledAddress: null,
			isHitted: false
		};
	},
	computed: {
		isEditMode() {
			return this.addressId !== 0;
		},
		/**
		 * @return {Boolean}
		 */
		checkAddress() {
			return !isNil(this.rendFilledAddress);
		}
	},
	created() {
		this.initAddress();
	},
	watch: {
		addressPayload: {
			handler(address) {
				this.rendAddress(address);
			},
			deep: true,
			immediate: true
		},
		addressId: {
			handler(n) {
				if (!isNil(n)) {
					this.addressId = n;
					this.initAddress();
					this.editAddressValue = this.isEditMode ? this.addressPayload : null;
				}
			},
			deep: true,
			immediate: true
		}
	},
	methods: {
		...mapActions({
			updateAddress: 'address/updateAddress',
			deleteAddress: 'address/deleteAddress'
		}),
		async initAddress() {
			this.$nextTick(async () => {
				if (!this.isEditMode) return;
				await this.getAddress(this.addressId);
			});
		},
		async getAddress(id) {
			const query = gql`
				query ($AddressId: ID!) {
					GetAddress(AddressId: $AddressId) {
						id
						street
						number
						box
						zip
						state
						city
						country
						countryCode
						countryKey
						other
						lat
						lng
					}
				}
			`;
			try {
				const {
					data: { GetAddress }
				} = await this.$apollo.query({
					query,
					variables: {
						AddressId: parseInt(id, 10)
					},
					fetchPolicy: 'no-cache'
				});
				this.rendFilledAddress = this.rendAddress(GetAddress);
				this.addressPayload = GetAddress;
				this.editAddressValue = GetAddress;
			} catch (e) {
				console.log({ e });
				this.rendFilledAddress = null;
				this.addressPayload = {};
				this.editAddressValue = {};
			}
		},
		rendAddress(addr = {}) {
			const address = addressFormatter(addr);
			return address.length > 0 ? address : this.FormMSG(5, 'No selected address...');
		},
		/**
		 * @param {Object} address
		 */
		handleAdressChange(address) {
			if (isNil(address)) return;

			this.addressPayload = {
				...this.addressPayload,
				...address
			};
			// console.log('addressPayload: ', this.addressPayload)
		},
		async handleDeleteAddress() {
			const _t = this.FormMSG;
			const txt = _t(10, 'Please confirm that you want delete this address from this location');

			this.$bvModal
				.msgBoxConfirm(txt, {
					title: _t(11, 'Please Confirm'),
					size: 'sm',
					buttonSize: 'sm',
					okVariant: 'danger',
					okTitle: _t(9798, 'YES'),
					cancelTitle: _t(776, 'NO'),
					footerClass: 'p-2',
					hideHeaderClose: false,
					centered: false
				})
				.then((s) => {
					if (!s) return;
					this.deleteAddress(this.addressPayload.id);
					this.rendFilledAddress = null;
				})
				.catch((e) => console.error({ e }));
		},
		async deleteAddress(id) {
			await this.$apollo.mutate({
				mutation: gql`
					mutation ($AddressId: ID!) {
						DelAddress(AddressId: $AddressId)
					}
				`,
				variables: {
					AddressId: parseInt(id)
				}
			});
			this.addressPayload = {};
			this.editAddressValue = null;
			this.rendAddress();
			this.$emit('delete', this.params.name);
		},
		// Modals handler
		openAdresseModal() {
			this.isAddressModalOpen = true;
		},
		handleAdressModalCancel() {
			this.isAddressModalOpen = false;
		},
		async handleAddressSubmit(e) {
			e.preventDefault();
			this.isHitted = true;

			if (this.invalid) {
				return;
			}

			if (!this.isEditMode) {
				const {
					data: { NewAddress }
				} = await this.$apollo.mutate({
					mutation: gql`
						mutation {
							NewAddress {
								id
							}
						}
					`
				});

				this.addressPayload.id = NewAddress.id;
				this.rendFilledAddress = this.rendAddress(this.addressPayload);
			}

			await this.updateAddress({
				id: this.addressPayload.id,
				address: this.addressPayload
			});

			this.getAddress(this.addressPayload.id);
			this.editAddressValue = this.addressPayload;

			this.$emit('change', {
				id: this.addressPayload.id,
				address: this.addressPayload,
				key: this.params.name
			});

			let message = this.FormMSG(7, 'Address updated successfully');

			if (!this.isEditMode) {
				message = this.FormMSG(8, 'Address created successfully');
			}

			this.createToastForMobile(this.FormMSG(6, 'Success'), message, '');
			this.isAddressModalOpen = false;
		}
	}
};
